<template>
  <v-card class="rounded-b-lg" :style="getStyleColor">
    <v-card-actions class="ma-0 pa-0 px-4">
      <v-btn tile :loading="loading" @click.stop="handleHeart" text>
        <v-icon
          :color="
            heartedFeedCommentIds && heartedFeedCommentIds.includes(item.id)
              ? 'red'
              : ''
          "
          left
        >
          mdi-heart
        </v-icon>
        {{
          item.__meta__ && item.__meta__.hearts_count
            ? item.__meta__.hearts_count
            : 0
        }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text tile plain @click.stop="$emit('reply', item)">
        <v-icon left small>mdi-reply</v-icon>
        {{ $lang("reply") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import VueTip from "@/components/VueTip";
import { stripScriptTags } from "@/helpers/utils";
import UploadArea from "@/components/UploadArea";
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
      heartedFeedCommentIds: "app/feed/heartedFeedCommentIds",
    }),
    getStyleColor() {
      return this.$store.getters.isThemeDark
        ? "background-color: #2a2a2a"
        : "background-color: #efefef";
    },
  },
  methods: {
    handleHeart() {
      this.toggleHeart();
    },
    toggleHeart() {
      this.loading = true;
      return axios
        .post("reactions/toggle/hearted/feed-comments/" + this.item.id)
        .then((res) => {
          // success
          if (res.data.resolved) {
            this.$store.dispatch("app/feed/patchHeartedFeedCommentIds", {
              add: this.item.id,
            });
          } else {
            this.$store.dispatch("app/feed/patchHeartedFeedCommentIds", {
              remove: this.item.id,
            });
          }
          this.item.__meta__.hearts_count = res.data.hearts_count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>