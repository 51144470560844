var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item && _vm.item.id)?_c('v-row',{staticClass:"mx-n2 justify-center",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('post-card',{attrs:{"item":_vm.item}}),_c('post-actions',{staticClass:"mb-0 mt-0 px-2",attrs:{"item":_vm.item}}),(false)?_c('v-card',{staticClass:"mt-3 py-2 px-0 rounded-lg"},[_c('v-card-actions',[_c('v-btn',{attrs:{"large":"","text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$lang("back"))+" ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-1 blue darken-4 white--text",attrs:{"large":"","text":""},on:{"click":function($event){$event.stopPropagation();_vm.showNewDialog = !_vm.showNewDialog}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-comment")]),_vm._v(" "+_vm._s(_vm.$lang("SubmitAComment"))+" ")],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-3 py-0 px-0 rounded-lg"},[_c('v-card-actions',{attrs:{"align":"center","justify":"center"}},[_c('v-avatar',{staticClass:"pa-0 ma-0",staticStyle:{"cursor":"pointer"},attrs:{"size":"40"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'profile',
              params: { id: _vm.authUser.username },
            })}}},[_c('v-img',{attrs:{"contain":"","src":_vm.authUser.profile.avatar}})],1),_c('v-card-actions',{staticClass:"grey--text text-left flex-grow-1 flex-shrink-1 py-4 ml-2",on:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.showNewDialog = !_vm.showNewDialog;
              _vm.newDialogInitialText = null;
            }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$lang("TypeSomething"))+" "),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-send")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('comments',{staticClass:"px-0 pt-2",attrs:{"refresh":_vm.refreshComments,"feed":_vm.item},on:{"reply":function (clickedItem) {
          _vm.newDialogInitialText = '@' + clickedItem.user.username + ' ';
          _vm.showNewDialog = !_vm.showNewDialog;
        }}})],1),_c('new-comment-dialog',{attrs:{"show":_vm.showNewDialog,"initial":_vm.newDialogInitialText,"feed":_vm.item},on:{"closed":function (val) { return (_vm.showNewDialog = val); },"submitted":_vm.handleCommentCreated}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }