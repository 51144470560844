<template>
  <div v-if="authUser && authUser.id && items && items.length > 0">
    <v-card
      :class="!item.approved ? 'unapproved rounded-lg mb-2' : 'rounded-lg mb-2'"
      v-for="item in items"
      :key="item.id"
      tile
      :style="acceptedStyles(item)"
    >
      <v-card-actions class="subtitle-1">
        <v-badge
          v-tooltip="$lang('PENDINGAPPROVAL')"
          color="orange"
          dot
          overlap
          top
          offset-y="-14"
          v-if="!item.approved"
          style="cursor: pointer"
        ></v-badge>
        <v-avatar
          style="cursor: pointer"
          @click.stop="
            $router.push({
              name: 'profile',
              params: { id: item.user.username },
            })
          "
          class="pa-0 ma-0 mr-2"
          size="32"
        >
          <v-img contain :src="item.user.avatar" />
        </v-avatar>
        <div
          style="cursor: pointer"
          @click.stop="
            $router.push({
              name: 'profile',
              params: { id: item.user.username },
            })
          "
        >
          <div>{{ item.user.display_name }}</div>
          <div class="mt-n1 caption grey--text">
            {{ item.created_at | fromNow }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <moderator-actions
          @removed="(itm) => (items = items.filter((el) => el.id != itm.id))"
          :item="item"
          uri="feed-comments"
          class="mr-0"
        />
        <v-icon
          color="green"
          v-tooltip="$lang('ACCEPTED')"
          small
          v-if="item.accepted"
          >mdi-check
        </v-icon>

        <v-btn
          v-tooltip="$lang('REMOVE')"
          v-if="item.user_id == authUser.id"
          color="red"
          @click.stop="handleRemove(item)"
          small
          icon
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <FeedCommentActions :comment="item" @finished="() => {}" />
      </v-card-actions>
      <v-card-text
        class="pa-0 ma-0 mb-2 px-4"
        :class="$store.getters.isThemeDark ? 'white--text' : 'black--text'"
      >
        <latex-html :html="item.text" />
      </v-card-text>
      <v-card-text v-if="item.image_url" class="pa-0 ma-0">
        <v-img contain :src="item.image_url"></v-img>
      </v-card-text>
      <comment-actions :item="item" @reply="$emit('reply', item)" />
    </v-card>
  </div>
  <v-card-text v-else class="subtitile-2 text-center mt-8">
    {{ $lang("NOCOMMENTS").ucFirst() }}
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import LatexHtml from "@/components/LatexHtml";
import ModeratorActions from "./ModeratorActions";
import CommentActions from "./CommentActions";
import FeedCommentActions from "@/app/ugcp/modules/FeedCommentActions";

export default {
  props: ["feed", "refresh"],
  components: {
    LatexHtml,
    ModeratorActions,
    CommentActions,
    FeedCommentActions,
  },
  data() {
    return {
      items: null,
      loading: false,
    };
  },
  watch: {
    feed(val) {
      if (val) {
        this.fetchItems(this.feed.id);
      }
    },
    refresh(val) {
      if (this.feed) {
        this.fetchItems(this.feed.id);
      }
    },
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    acceptedStyles(item) {
      let style = "";
      if (item && item.accepted) {
        style += "box-shadow: 0px 0px 0px 1px green;";
        if (this.$store.getters.isThemeDark) {
          style += "background:#141e14;";
          style += "color:white";
        } else {
          style += "background:#dcffdc;";
          style += "color:black";
        }
      }
      return style;
    },
    handleRemove(item) {
      if (
        !confirm(
          `Are you sure to delete ${
            item.text ? item.text.stripTags() : item.id
          }?`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      bus.$emit("showWait");
      return axios
        .delete("feed-comments/" + item.id)
        .then((res) => {
          this.items = this.items.filter((el) => {
            return el.id != item.id;
          });
        })
        .catch((err) => {
          console.log("err", err.response);
          if (err.response.status == 404) {
            this.items = this.items.filter((el) => el.id != item.id);
          }
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    fetchItems(id) {
      this.loading = true;
      bus.$emit("showWait");

      return axios
        .get("feed/public/" + id + "/comments")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    if (this.feed) {
      this.fetchItems(this.feed.id);
    }
  },
};
</script>

<style>
.unapproved {
  box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
}
</style>