<template>
  <v-row v-if="item && item.id" dense no-gutters class="mx-n2 justify-center">
    <v-col cols="12" md="6">
      <post-card :item="item" />
      <post-actions class="mb-0 mt-0 px-2" :item="item" />
      <v-card v-if="false" class="mt-3 py-2 px-0 rounded-lg">
        <v-card-actions>
          <v-btn large text @click="$router.go(-1)">
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $lang("back") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="flex-grow-1 flex-shrink-1 blue darken-4 white--text"
            text
            @click.stop="showNewDialog = !showNewDialog"
          >
            <v-icon left>mdi-comment</v-icon>
            {{ $lang("SubmitAComment") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mt-3 py-0 px-0 rounded-lg">
        <v-card-actions align="center" justify="center">
          <v-avatar
            style="cursor: pointer"
            @click.stop="
              $router.push({
                name: 'profile',
                params: { id: authUser.username },
              })
            "
            class="pa-0 ma-0"
            size="40"
          >
            <v-img contain :src="authUser.profile.avatar"></v-img>
          </v-avatar>
          <v-card-actions
            @click.stop="
              () => {
                showNewDialog = !showNewDialog;
                newDialogInitialText = null;
              }
            "
            class="grey--text text-left flex-grow-1 flex-shrink-1 py-4 ml-2"
          >
            {{ $lang("TypeSomething") }}
            <v-spacer></v-spacer>
            <v-icon>mdi-send</v-icon>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12">
      <comments
        class="px-0 pt-2"
        :refresh="refreshComments"
        :feed="item"
        @reply="
          (clickedItem) => {
            newDialogInitialText = '@' + clickedItem.user.username + ' ';
            showNewDialog = !showNewDialog;
          }
        "
      />
    </v-col>

    <new-comment-dialog
      :show="showNewDialog"
      :initial="newDialogInitialText"
      :feed="item"
      @closed="(val) => (showNewDialog = val)"
      @submitted="handleCommentCreated"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import Comments from "../modules/Comments";
import VueTip from "@/components/VueTip";
import FabButton from "@/components/FabButton";
import NewCommentDialog from "./NewCommentDialog";
import PostCard from "../modules/PostCard";
import PostActions from "../modules/PostActions";

export default {
  name: "feed-post",
  components: {
    // FabButton,
    Comments,
    PostCard,
    PostActions,
    NewCommentDialog,
  },

  data() {
    return {
      item: {},
      refreshComments: null,
      loading: true,
      showNewDialog: false,
      newDialogInitialText: "",
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    "$route.params.id"(val) {
      if (val && val > 0) {
        this.fetchItem(this.$route.params.id);
      }
    },
  },
  methods: {
    handleFabClick() {
      this.showNewDialog = true;
      console.log("fab clicked");
    },
    handleCommentCreated() {
      this.refreshComments = !this.refreshComments;
      this.fetchItem(this.$route.params.id);
    },
    fetchItem(id) {
      this.loading = true;
      bus.$emit("showWait");

      axios
        .get("feed/mine/and/approved/" + id)
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.$router.replace({ name: "feed" });
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>

<style>
.img-cls {
  width: 100px;
}
</style>